import React from 'react'
import { Navigate } from 'react-router-dom'
import StorageHelper from '../HttpHelper/StorageHelper'
const Protected = ({ children }) => {
  const token = StorageHelper.getToken()

  if (!!!token) {
    return <Navigate to="/login" replace />
  }
  return children
}
export default Protected
