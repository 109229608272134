class StorageHelper {
  setToken(data) {
    localStorage.setItem("blackhedge_token", data);
  }

  getToken() {
    return localStorage.getItem("blackhedge_token");
  }
  getUserData() {
    return JSON.parse(localStorage.getItem("blackhedge_admin_details"));
  }

  removeToken() {
    localStorage.removeItem("blackhedge_admin_details");
    localStorage.removeItem("blackhedge_token");
    
    return localStorage.clear()
  }
}

export default StorageHelper = new StorageHelper();
