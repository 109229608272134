import { createContext, useState } from 'react'
import React from 'react'

const BlackhedgeContent = createContext()
const BlackhedgeWrapper = ({ children }) => {
  const [BLACKHEDGEData, setBLACKHEDGEData] = useState(null)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [statistics, setStatistics] = useState(null)




  return (
    <BlackhedgeContent.Provider value={{ BLACKHEDGEData, setBLACKHEDGEData ,bookingDetails, setBookingDetails,statistics, setStatistics}}>
      {children}
    </BlackhedgeContent.Provider>
  )
}

export default BlackhedgeContent
export { BlackhedgeWrapper }
